import { useAuthStore } from '@/stores';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { RouteNames } from './routeNames';

export const requiresAuthGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): boolean => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const authStore = useAuthStore();
        const isLoggedIn = authStore.isLoggedIn;

        if (!isLoggedIn) {
            if (to && to.fullPath !== '/') {
                next({
                    name: RouteNames.Login,
                    query: { redirect: to.fullPath },
                });
            } else {
                next({ name: RouteNames.Login });
            }
        } else {
            next();
        }
        return true;
    }
    return false;
};

export const anonymousOnlyGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): boolean => {
    if (to.matched.some((record) => record.meta.anonymousOnly)) {
        const authStore = useAuthStore();
        const isAnonymous = !authStore.isLoggedIn;

        if (!isAnonymous) {
            next({
                name: RouteNames.Menu,
            });
        } else {
            next();
        }
        return true;
    }
    return false;
};
