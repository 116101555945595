<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import { useChromePrintingApi } from '@/utils/printing';
import Toast from '@/views/AppShell/Toast.vue';

const route = useRoute();
const layoutMeta = computed(() => route.meta.layout || {});

onMounted(() => {
    const { enablePrintingAPI } = useChromePrintingApi();
    enablePrintingAPI();
});
</script>

<template>
    <DefaultLayout
        :content-size="layoutMeta?.width"
        :hide-nav-bar="layoutMeta?.hideNavBar"
        :disable-menu="layoutMeta?.disableMenu"
    >
        <RouterView />
    </DefaultLayout>
    <BaseModal />
    <Toast />
</template>
