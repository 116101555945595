<script setup lang="ts">
import SvgIcon, { type SvgIconName } from '@/components/SvgIcon.vue';
import { computed, type ButtonHTMLAttributes } from 'vue';

export interface ButtonProps {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'outline' | 'white' | 'link';
    size?: 'small' | 'medium' | 'large';
    startIcon?: SvgIconName;
    endIcon?: SvgIconName;
    block?: boolean;
    disabled?: boolean;
    type?: ButtonHTMLAttributes['type'];
}

const props = withDefaults(defineProps<ButtonProps>(), {
    variant: undefined,
    startIcon: undefined,
    endIcon: undefined,
    size: 'medium',
    type: 'button',
});

const classes = computed(() => ({
    button: true,
    [`-${props.variant}`]: props.variant,
    '-block': props.block,
    [`-${props.size || 'medium'}`]: true,
}));

</script>

<template>
    <button
        :class="classes"
        v-bind="$attrs"
        :disabled="disabled"
        :type="type"
    >
        <span
            v-if="startIcon"
            class="start-icon"
        >
            <SvgIcon :name="startIcon" />
        </span>
        <span class="content">
            <slot />
        </span>
        <span
            v-if="endIcon"
            class="end-icon"
        >
            <SvgIcon :name="endIcon" />
        </span>
    </button>
</template>

<style lang="scss" scoped>
.button {
    --button-height: 4rem;
    --button-size: 1.6rem;
    --button-border-radius: .6rem;
    --button-color: var(--color-white);
    --button-bg: var(--color-gray-coal-4);
    --button-border-color: var(--color-gray-coal-4);
    --button-hover-color: var(--button-color);
    --button-hover-bg: var(--color-gray-coal-5);
    --button-hover-border-color: var(--button-hover-bg);
    --button-focus-border-color: var(--color-gray-coal-3);
    --button-active-color: var(--color-white);
    --button-active-bg: var(--color-gray-coal-3);
    --button-active-border-color: var(--button-active-bg);
    --button-disabled-color: var(--color-gray-coal-3);
    --button-disabled-bg: color-mix(in srgb, var(--color-gray-cool-9), var(--color-black) 12%);
    --button-disabled-border-color: color-mix(in srgb, var(--color-gray-cool-9), var(--color-black) 12%);

    @extend %reset-button;

    cursor: pointer;
    user-select: none;

    position: relative;

    display: inline-grid;
    grid-template-areas: 'prepend content append';
    grid-template-columns: max-content auto max-content;
    align-items: center;
    justify-content: center;

    max-inline-size: 100%;
    block-size: var(--button-height);
    padding: 0 1.5em;

    font-size: var(--button-size);
    font-weight: var(--font-weight-medium);
    line-height: 1.25;
    color: var(--button-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;

    background-color: var(--button-bg);
    border: 1px solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    outline: 0;

    transition: background-color .3s ease, color .3s ease, border-color .3s ease;

    &:focus-visible {
        border-color: var(--button-focus-border-color);
    }

    &:disabled {
        pointer-events: none;

        color: var(--button-disabled-color);

        opacity: .6;
        background-color: var(--button-disabled-bg);
        border-color: var(--button-disabled-border-color);
    }

    &:hover {
        color: var(--button-hover-color);
        background-color: var(--button-hover-bg);
        border-color: var(--button-hover-border-color);
    }

    &:active {
        color: var(--button-active-color);
        background-color: var(--button-active-bg);
        border-color: var(--button-active-border-color);
    }

    &.-primary {
        --button-color: var(--color-gray-coal-1);
        --button-bg: var(--color-primary);
        --button-border-color: var(--button-bg);
        --button-hover-bg: var(--color-primary-light);
        --button-active-bg: var(--color-primary-dark);
        --button-active-color: var(--button-color);
    }

    &.-secondary {
        --button-color: var(--color-gray-coal-1);
        --button-bg: var(--color-secondary);
        --button-border-color: var(--color-secondary-dark);
        --button-hover-bg: var(--color-toadstool);
        --button-active-bg: var(--color-secondary-dark);
        --button-active-color: var(--button-color);
    }

    &.-tertiary {
        --button-color: var(--color-gray-coal-2);
        --button-bg: var(--color-seashell);
        --button-border-color: var(--color-blush-4);
        --button-hover-bg: var(--color-seashell-light);
        --button-hover-border-color: var(--color-blush-2);
        --button-active-bg: var(--color-blush-4);
        --button-active-color: var(--button-color);
    }

    &.-white {
        --button-color: var(--color-gray-coal-2);
        --button-bg: var(--color-white);
        --button-border-color: var(--button-bg);
        --button-hover-bg: var(--button-bg);
        --button-active-bg: var(--button-bg);
        --button-active-color: var(--button-color);
    }

    &.-danger {
        --button-color: var(--color-white);
        --button-bg: var(--color-danger);
        --button-border-color: var(--button-bg);
        --button-hover-bg: color-mix(in srgb, var(--color-danger), #000000 15%);
        --button-active-bg: var(--button-hover-bg);
        --button-active-border-color: var(--color-gray-coal-4);
    }

    &.-link {
        --button-color: currentcolor;
        --button-bg: transparent;
        --button-hover-color: var(--color-cadet-dark);
        --button-hover-bg: var(--button-bg);
        --button-active-bg: var(--button-bg);
        --button-active-color: var(--button-color);

        block-size: auto;
        padding: 0;

        font-weight: var(--font-weight-normal);
        line-height: 1;
        text-decoration: underline;

        border: 0;
        border-radius: 0;

        &:focus-visible {
            outline: 1px solid var(--color-seafoam);
            outline-offset: .4rem;
        }

        &:disabled {
            text-decoration: none;
            background: transparent;
        }
    }

    &.-outline {
        --button-bg: var(--color-white);
        --button-color: var(--color-gray-coal-2);
        --button-border-color: var(--color-gray-coal-4);
        --button-hover-bg: var(--color-seashell);
        --button-hover-border-color: var(--color-blush-4);
        --button-active-color: var(--color-gray-coal-2);
        --button-active-bg: var(--color-secondary-light);
        --button-active-border-color: var(--color-gray-coal-4);
        --button-disabled-border-color: var(--button-disabled-bg);

        &:focus-visible {
            box-shadow: 0 0 0 .3rem var(--color-toadstool);
        }
    }

    &.-block {
        inline-size: 100%;
    }

    &.-small {
        --button-height: 2.8rem;
        --button-size: 1.3rem;
    }

    &.-large {
        --button-height: 6rem;
    }
}

.content,
.start-icon,
.end-icon {
    display: flex;
    align-items: center;
}

.content {
    grid-area: content;
    justify-content: center;
    max-inline-size: 100%;
    white-space: nowrap;
}

.start-icon {
    grid-area: prepend;
    margin-inline-start: calc(var(--button-height) / -9);
    margin-inline-end: calc(var(--button-height) / 4.5);
}

.end-icon {
    grid-area: append;
    margin-inline-start: calc(var(--button-height) / 4.5);
    margin-inline-end: calc(var(--button-height) / -9);
}
</style>
