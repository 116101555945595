<script setup lang="ts">
import { h, ref, watch } from 'vue';
import VSelect, { type VueSelectInstance, type VueSelectProps, type VueSelectSlotScope } from 'vue-select';
import 'vue-select/dist/vue-select.css';
import SvgIcon from '@/components/SvgIcon.vue';

export type BaseSelectOptionObject = {
    [key: string]: unknown;
    label: string;
}

export type BaseSelectOption = string | BaseSelectOptionObject;

export interface BaseSelectProps {
    id: string;
    placeholder?: string;
    modelValue: null | BaseSelectOption;
    error?: string;
    disabled?: boolean;
    readonly?: boolean;
    onSearch?: ((value: string, loading: (val: boolean) => void) => void);
    options: BaseSelectOption[];
}

interface Props extends Partial<Omit<VueSelectProps,
    'options' | 'placeholder' | 'disabled' | 'value'
>>, BaseSelectProps {}

interface Emits {
    (event: 'update:modelValue', value: BaseSelectOption): void;
}

const props = defineProps<Props>();
defineEmits<Emits>();

const OpenIndicator = h(SvgIcon, { name: 'arrow-down' });
const selectRef = ref<VueSelectInstance>();
const selected = ref<BaseSelectOption | null>(props.modelValue);

defineExpose({ selectRef });

watch(
    () => props.modelValue,
    (newValue) => {
        if (newValue !== selected.value) {
            selected.value = newValue;

            if (newValue === '') {
                selectRef.value?.clearSelection();
            }
        }
    },
);
</script>

<template>
    <BaseInputWrapper :loading="loading">
        <VSelect
            v-bind="$attrs"
            ref="selectRef"
            class="base-select"
            :input-id="id"
            :class="{'-loading': loading, '-disabled': disabled}"
            :placeholder="placeholder"
            :filterable="filterable"
            :searchable="searchable"
            :options="options"
            :reduce="reduce"
            :model-value="selected"
            :disabled="disabled || readonly"
            :clearable="clearable"
            :components="{OpenIndicator}"
            :dropdown-should-open="dropdownShouldOpen"
            @search="onSearch"
            @update:model-value="$emit('update:modelValue', $event)"
        >
            <template #spinner="{ loading }">
                <Loader
                    v-if="loading"
                    class="base-select__loader"
                    size="small"
                    inline
                    show
                />
            </template>
            <template
                v-for="(_, name) in $slots"
                #[name]="slotProps"
            >
                <slot
                    :name="name"
                    v-bind="slotProps as VueSelectSlotScope || {}"
                />
            </template>
        </VSelect>
    </BaseInputWrapper>
</template>

<style lang="scss">
.base-select {
    .vs__dropdown-toggle {
        transition: .15s ease border-color;
    }

    .vs__search {
        &::placeholder {
            color: var(--field-placeholder-color);
            opacity: 1;
        }
    }

    .vs__selected-options {
        padding: 1.25rem 0;
    }

    .vs__actions {
        .svg-icon {
            --svg-icon-size: 1.1em;
            --svg-icon-color: var(--color-chimmichurri);
        }
    }

    &.-disabled {
        .vs__dropdown-toggle {
            cursor: not-allowed;
        }
    }

    .-has-error & {
        .vs__search {
            &::placeholder {
                opacity: .5;
            }
        }
    }

    &.vs--single {
        // stylelint-disable-next-line selector-max-class
        &.vs--searching:not(.vs--open, .vs--loading) .vs__search {
            opacity: 1;
        }
    }

    ~ .form-input__loader {
        inset-inline-end: 3rem;
    }
}
</style>

<style lang="scss" scoped>
.base-select {
    --vs-font-size: 1em;
    --vs-line-height: var(--field-line-height);
    --vs-border-radius: var(--field-border-radius);
    --vs-border-color: var(--field-border-color);
    --vs-dropdown-option-padding: .8rem 1rem;
    --vs-dropdown-option-color: var(--default-text-color);
    --vs-dropdown-option--active-color: var(--vs-dropdown-option-color);
    --vs-dropdown-option--active-bg: var(--color-blush-3);

    position: relative;

    display: block;

    inline-size: 100%;
    margin: var(--field-margin);

    font-family: var(--default-font-family);
    font-size: var(--field-font-size);
    line-height: var(--field-line-height);
    color: var(--field-color);

    background: var(--field-bg);

    &.-disabled {
        --field-color: var(--color-disabled-color);
    }

    &:hover:not(.-disabled) {
        --field-border-color: var(--color-primary-light);
    }

    // stylelint-disable-next-line no-descending-specificity
    .-has-error & {
        --field-border-color: var(--field-error-border-color);
        --field-color: var(--field-error-color);
        --field-bg: var(--field-error-bg);
        --field-placeholder-color: var(--field-error-placeholder-color);

        &:hover {
            --field-border-color: var(--field-error-border-color);
        }
    }
}

.base-select__loader {
    margin-inline-start: 1rem;
    margin-inline-end: .2rem;
}
</style>
