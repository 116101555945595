<script setup lang="ts">
import { useUIStore } from '@/stores';

const uiStore = useUIStore();
</script>

<template>
    <div class="logout-modal">
        <div class="logout-modal__content">
            <h4 class="logout-modal__title">
                <Translate msg="appShell.logoutModal.title" />
            </h4>
            <p class="logout-modal__text">
                <Translate msg="appShell.logoutModal.text" />
            </p>
        </div>
        <Button
            class="logout-modal__button"
            @click="uiStore.closeModal"
            block
        >
            <Translate msg="appShell.logoutModal.confirm" />
        </Button>
    </div>
</template>

<style lang="scss" scoped>
.logout-modal {
    margin: -3rem;
    text-align: center;
}

.logout-modal__content {
    padding: 2.4rem;
}

.logout-modal__title {
    margin: 0 0 3.2rem;
}

.logout-modal__button {
    --button-bg: var(--color-white);
    --button-color: var(--color-cadet);
    --button-border-color: var(--default-border-color);
    --button-hover-bg: var(--color-toadstool);
    --button-hover-color: var(--color-gray-cool-2);
    --button-hover-border-color: var(--default-border-color);
    --button-size: 2.4rem;
    --button-height: 6.7rem;

    border-width: 1px 0 0;
    border-radius: 0 0 var(--modal-border-radius) var(--modal-border-radius);
}
</style>
