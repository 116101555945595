import { get } from '@/utils/requestWrapper';
import type { ApiSearchParams } from '@/types/api';
import type { Country, PaymentMethod, PosUnit, Store, Voucher } from '@/types/common';

const URL = {
    COUNTRIES: '/shop/countries',
    POS_UNITS: '/pos/pos-units',
    POS_UNIT_DETAIL: '/pos/pos-units/:id',
    STORE_DETAIL: '/pos/stores/:id',
    PAYMENT_METHODS: '/shop/payment-methods',
    VOUCHER_DETAIL: '/shop/voucher/:voucherCode',
};

function getCountries(params: ApiSearchParams) {
    return get<Country[]>({
        url: URL.COUNTRIES,
        params,
    });
}

function getPosUnits() {
    return get<PosUnit[]>({
        url: URL.POS_UNITS,
    });
}

function getPosUnitDetail({ id }: { id: string}) {
    return get<PosUnit>({
        url: URL.POS_UNIT_DETAIL,
        pathParams: {
            id,
        },
    });
}

function getStoreDetail({ id }: { id: string }) {
    return get<Store>({
        url: URL.STORE_DETAIL,
        pathParams: {
            id,
        },
    });
}

function getPaymentMethods() {
    return get<PaymentMethod[]>({
        url: URL.PAYMENT_METHODS,
    });
}

function getVoucher(voucherCode: string) {
    return get<Voucher>({
        url: URL.VOUCHER_DETAIL,
        pathParams: {
            voucherCode,
        },
    });
}

export default {
    getCountries,
    getPosUnits,
    getPosUnitDetail,
    getStoreDetail,
    getPaymentMethods,
    getVoucher,
};
