<script lang="ts" setup>
import { computed, ref, toRefs, watch } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useRoute } from 'vue-router';

interface Props {
    isOpen: boolean;
    onCloseIntent: () => void;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
}

const props = defineProps<Props>();
const { isOpen, onAfterClose, onAfterOpen } = toRefs(props);
const panelRef = ref<HTMLElement>();
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(panelRef, {
    allowOutsideClick: true,
});
const route = useRoute();

function keyDownListener(event: KeyboardEvent) {
    if (event.key === 'Escape') {
        props.onCloseIntent();
    }
}

function onTransitionEnd() {
    if (isOpen.value) {
        activateFocusTrap();

        if (onAfterOpen?.value && typeof onAfterOpen.value === 'function') {
            onAfterOpen.value();
        }
    } else {
        deactivateFocusTrap();

        if (onAfterClose?.value && typeof onAfterClose.value === 'function') {
            onAfterClose.value();
        }
    }
}

const slideOutPanelWidth = computed(() => {
    return typeof route.meta?.slideOutPanel === 'object' ? route.meta.slideOutPanel.width : undefined;
});

watch(() => props.isOpen, (newValue, oldValue) => {
    if (newValue && !oldValue) {
        document.addEventListener('keydown', keyDownListener);
    } else {
        document.removeEventListener('keydown', keyDownListener);
    }
}, { immediate: true });
</script>

<template>
    <aside
        ref="panelRef"
        class="slide-out-panel"
        :class="{'-open': isOpen}"
        :style="{
            '--slide-out-panel-width': slideOutPanelWidth,
        }"
    >
        <div
            class="slide-out-panel__content"
            @transitionend.self="onTransitionEnd"
        >
            <IconButton
                icon-name="cross"
                class="slide-out-panel__close-button"
                @click="onCloseIntent"
                :tabindex="isOpen ? 0 : -1"
            />
            <div
                class="slide-out-panel__scroll-wrapper"
                data-slide-out-panel
            />
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.slide-out-panel {
    --slide-out-panel-width: auto;

    pointer-events: none;
    display: flex;
    align-items: stretch;

    &::after {
        content: '';

        position: absolute;
        z-index: -1;
        inset: 0;

        opacity: 0;
        background-color: var(--color-gray-cool-4);

        transition: .5s ease-in-out opacity;
    }

    &.-open {
        pointer-events: auto;
        display: flex;
        backdrop-filter: blur(.3rem);

        &::after {
            opacity: .4;
        }
    }
}

.slide-out-panel__content {
    position: relative;
    transform: translate3d(calc(100% + 9rem), 0, 0);

    inline-size: var(--slide-out-panel-width);
    max-inline-size: calc(100% - 10rem);
    margin: 0 0 0 auto;
    padding: 3rem 3rem 5rem 6rem;

    background-color: var(--color-white);
    box-shadow: -1rem 0 2.3rem 0 rgba(0 0 0 / 23%);

    // stylelint-disable-next-line plugin/no-low-performance-animation-properties
    transition: .5s ease-in-out transform, .3s ease inline-size;

    .-open & {
        transform: translate3d(0, 0, 0);
    }
}

.slide-out-panel__close-button {
    --icon-button-font-size: 3.8rem;
    --button-color: var(--color-gray-cool-2);
    --button-bg: var(--color-white);
    --button-hover-color: var(--color-gray-coal-2);
    --button-hover-bg: var(--color-seashell);
    --button-active-color: var(--color-gray-coal-2);
    --button-active-bg: var(--color-white);

    position: absolute;
    inset-block-start: 1.5rem;
    inset-inline-end: calc(100% + 2rem);

    padding: 1.2rem;

    border: none;

    &:focus-visible {
        outline: 1px solid var(--color-seafoam);
        outline-offset: .1rem;
    }
}

.slide-out-panel__scroll-wrapper {
    overflow-y: auto;
    block-size: 100%;
    max-block-size: 100%;
    padding-inline-end: 3rem;
}
</style>
