<script setup lang="ts">
import { useUIStore } from '@/stores';

export interface ConfirmationModalProps {
    message: string;
    buttonText: string;
}

defineProps<ConfirmationModalProps>();

const uiStore = useUIStore();
</script>

<template>
    <div class="confirmation-modal">
        <figure class="confirmation-modal__icon">
            <SvgIcon name="checkmark" />
        </figure>
        <h4 class="confirmation-modal__title">
            {{ message }}
        </h4>
        <Button
            variant="primary"
            block
            size="large"
            @click="uiStore.closeModal"
        >
            {{ buttonText }}
        </Button>
    </div>
</template>

<style lang="scss" scoped>
.confirmation-modal {
    padding-inline: 2.6rem;
    text-align: center;
}

.confirmation-modal__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1;
    inline-size: 7rem;
    margin: 0;

    font-size: 3.4rem;
    color: var(--color-success);

    object-fit: contain;
    object-position: center;
    border: 3px solid currentcolor;
    border-radius: 100%;
}

.confirmation-modal__title {
    margin-block-end: 4.2rem;
}
</style>
