export function devTodoAlert() {
    // eslint-disable-next-line no-alert
    alert('TODO: Implement this feature');
}

export function useSimpleUserAuth() {
    return (
        ['development', 'test', 'staging'].includes(import.meta.env.MODE)
        && !!import.meta.env.VITE_FIXED_DEVICE_IDENTIFIER
    );
}
