import { type AsyncEntity, AsyncStatus } from '@/types/api';
import { isApiError, isApiMessageError, isApiPlatformError } from '@/utils/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncFetchHandler<EntityType, ArgsType = any>({
    asyncEntity,
    service,
    resetDataOnTrigger = false,
    resetDataOnError = false,
}: {
    asyncEntity: AsyncEntity<EntityType>;
    service: (args: ArgsType) => Promise<EntityType>;
    resetDataOnTrigger?: boolean;
    resetDataOnError?: boolean;
}): (args: ArgsType) => Promise<EntityType | Error> {
    asyncEntity.error = null;
    asyncEntity.status = AsyncStatus.Busy;

    return async function (args) {
        asyncEntity.error = null;
        asyncEntity.status = AsyncStatus.Busy;

        if (resetDataOnTrigger) {
            asyncEntity.data = null;
        }

        try {
            const response = await service(args);
            asyncEntity.data = response;
            asyncEntity.status = AsyncStatus.Success;
            return response;
        } catch (error) {
            if (isApiError(error)) {
                if (error.wasCancelled) {
                    // don't update state props when request is cancelled
                    return new Error('Request cancelled');
                }
            }

            asyncEntity.status = AsyncStatus.Error;

            if (resetDataOnError) {
                asyncEntity.data = null;
            }

            if (isApiError(error)) {
                asyncEntity.error = error;

                if (isApiMessageError(error)) {
                    return new Error(error.response.message);
                } else if (isApiPlatformError(error)) {
                    return new Error(error.response.title);
                }
            }

            if (error instanceof Error && error.message) {
                asyncEntity.error = error.message;
                return new Error(error.message);
            }

            return new Error(error as string);
        }
    };
}

export function resetAsyncEntity<EntityType>(state: AsyncEntity<EntityType>): void {
    state.data = null;
    state.error = null;
    state.status = AsyncStatus.Initial;

    if (state.abortController) {
        if (state.abortController.abort) {
            state.abortController.abort();
        }
        state.abortController = new AbortController();
    }
}
