<script setup lang="ts">
import { useConstantsStore, useUIStore } from '@/stores';
import { onBeforeMount, ref } from 'vue';
import { useChromePrintingApi } from '@/utils/printing';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import type { BaseSelectOption, BaseSelectOptionObject } from '@/components/base/BaseSelect.vue';

interface Props {
    onPrinterSelected?: (printerId: string) => void;
}

interface Option extends BaseSelectOptionObject {
    id: string;
}

const printers = ref<Option[]>([]);
const value = ref('');
const props = defineProps<Props>();
const uiStore = useUIStore();
const constantsStore = useConstantsStore();
const { preferredPrinterId } = storeToRefs(constantsStore);
const { t } = useI18n();
const { getPrinters } = useChromePrintingApi();

function updateSelectedValue(newValue: BaseSelectOption) {
    value.value = newValue as string;
}

function onSubmit() {
    preferredPrinterId.value = value.value;

    if (typeof props.onPrinterSelected === 'function') {
        props.onPrinterSelected(value.value);
    }

    uiStore.closeModal();
}

onBeforeMount(async () => {
    const ps = await getPrinters();
    printers.value = ps.map((p) => ({ id: p.id, label: p.name }));
    const currentPreferred = ps.find((p) => p.id === preferredPrinterId.value);
    value.value = currentPreferred?.id || '';
});
</script>

<template>
    <div class="preferred-printer-modal">
        <FormGroup
            id="preferred-printer"
            :label="t('appShell.selectPreferredPrinterModal.label')"
            v-slot="{id: id, label: label}"
            hide-label
        >
            <BaseSelect
                :id="id"
                :placeholder="label"
                :options="printers"
                :reduce="(option: any) => option.id"
                :model-value="value"
                @update:model-value="updateSelectedValue"
            />
        </FormGroup>
        <Button
            class="preferred-printer-modal__button"
            variant="primary"
            @click="onSubmit"
            block
        >
            <Translate msg="appShell.selectPreferredPrinterModal.confirm" />
        </Button>
    </div>
</template>

<style lang="scss" scoped>
.preferred-printer-modal {
    --vs-dropdown-max-height: 12rem;

    position: relative;
    padding-block: 3rem;
}

.preferred-printer-modal__button {
    margin-block-start: 4rem;
}
</style>
