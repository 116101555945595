import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { type AsyncEntity, AsyncStatus } from '@/types/api';
import { asyncFetchHandler, resetAsyncEntity } from '@/utils/asyncEntity';
import type { Wishlist } from '@/types/wishlist';
import WishlistService from '@/services/WishlistService';

export const useWishlistStore = defineStore('wishlist', () => {
    const wishlistSearchEntity: AsyncEntity<Wishlist[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
        abortController: new AbortController(),
    });

    const wishlistForProductSearchEntity: AsyncEntity<Wishlist[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
        abortController: new AbortController(),
    });

    const wishlistSearchQuery = ref<string>('');

    function searchWishlist({ query }: { query?: string }) {
        if (wishlistSearchEntity.status === AsyncStatus.Busy && wishlistSearchEntity?.abortController?.abort) {
            wishlistSearchEntity.abortController.abort();
        }

        wishlistSearchEntity.abortController = new AbortController();
        return asyncFetchHandler({
            asyncEntity: wishlistSearchEntity,
            service: WishlistService.getWishlists,
        })({
            search: query,
            itemsPerPage: 20,
            signal: wishlistSearchEntity.abortController.signal,
        });
    }

    function searchWishlistForProduct({ query, productCode }: { query?: string; productCode?: string }) {
        if (wishlistForProductSearchEntity.status === AsyncStatus.Busy
            && wishlistForProductSearchEntity?.abortController?.abort) {
            wishlistForProductSearchEntity.abortController.abort();
        }

        wishlistForProductSearchEntity.abortController = new AbortController();
        return asyncFetchHandler({
            asyncEntity: wishlistForProductSearchEntity,
            service: WishlistService.getWishlists,
        })({
            search: query,
            itemsPerPage: 20,
            signal: wishlistForProductSearchEntity.abortController.signal,
            'items.productVariant.code': productCode,
        });
    }

    function resetWishlistSearch() {
        resetAsyncEntity(wishlistSearchEntity);
        resetAsyncEntity(wishlistForProductSearchEntity);
    }

    return {
        wishlistSearchQuery, wishlistSearchEntity, searchWishlist, resetWishlistSearch,
        wishlistForProductSearchEntity, searchWishlistForProduct,
    };
}, {
    persist: {},
});
