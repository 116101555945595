<script lang="ts">
export const IconNames = [
    'arrow-up',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'baby',
    'bell',
    'bin',
    'calendar',
    'cart',
    'checkmark',
    'checkmark-round',
    'chevron-up',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'cross',
    'document',
    'email',
    'error',
    'euro',
    'logout',
    'minus',
    'pay-desk',
    'pencil',
    'printer',
    'plus',
    'plus-round',
    'search',
    'store',
    'tag',
    'user',
    'user2',
    'user-filled',
    'user-filled-add',
    'wallet',
] as const;

export type SvgIconName = typeof IconNames[number];
export default {};
</script>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

interface Props {
    name: SvgIconName;
}

const props = defineProps<Props>();
const icon = defineAsyncComponent({
    loader: () => import(`../../src/assets/icons/${props.name}.svg`),
});
</script>

<template>
    <component
        :is="icon"
        class="svg-icon"
        v-bind="$attrs"
    />
</template>

<style lang="scss" scoped>
.svg-icon {
    --svg-icon-size: 1em;
    --svg-icon-width: var(--svg-icon-size);
    --svg-icon-height: var(--svg-icon-size);
    --svg-icon-color: currentcolor;

    inline-size: var(--svg-icon-width);
    block-size: var(--svg-icon-height);
    fill: var(--svg-icon-color);
}
</style>
