import type {
    ApiError,
    ApiErrorResponse,
    ApiMessageError,
    ApiPlatformError,
    ApiPlatformHydraError,
    ApiPlatformViolation,
} from '@/types/api';
import { Http } from '@status/codes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiError(error: any): error is ApiError<ApiErrorResponse> {
    if (typeof error !== 'object') {
        return false;
    }

    const apiError = error as ApiError<ApiMessageError>;

    return (
        apiError
        && typeof apiError.status === 'number'
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiMessageError(error: any): error is ApiError<ApiMessageError> {
    if (typeof error !== 'object') {
        return false;
    }

    const apiError = error as ApiError<ApiMessageError>;

    return (
        apiError
        && typeof apiError.status === 'number'
        && typeof apiError.response.message === 'string'
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiPlatformError(error: any): error is ApiError<ApiPlatformError> {
    if (typeof error !== 'object') {
        return false;
    }

    const apiError = error as ApiError<ApiPlatformError>;

    return (
        apiError
        && typeof apiError.status === 'number'
        && typeof apiError.response.detail === 'string'
        && typeof apiError.response.title === 'string'
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiPlatformHydraError(error: any): error is ApiError<ApiPlatformHydraError> {
    if (typeof error !== 'object') {
        return false;
    }

    const apiError = error as ApiError<ApiPlatformHydraError>;

    return (
        apiError
        && typeof apiError.status === 'number'
        && typeof apiError.response['hydra:title'] === 'string'
        && typeof apiError.response['hydra:description'] === 'string'
    );
}

export function getErrorMessageToShow(error: string | ApiError<ApiErrorResponse>) {
    if (error) {
        if (isApiError(error)) {
            if (error.status === Http.RequestTimeout || error.status === Http.GatewayTimeout) {
                return 'error.timeout';
            }

            if (error.status >= 500) {
                return 'error.default.unexpected';
            }

            if (typeof error.response === 'string') {
                return error.response;
            }

            if ((error.response as ApiMessageError)?.message) {
                return (error.response as ApiMessageError).message;
            }

            if ((error.response as ApiPlatformError)?.title) {
                const err = error.response as ApiPlatformError;
                if (err?.detail) {
                    return `${err.title} - ${err.detail}`;
                }

                return err.title;
            }

            if ((error.response as ApiPlatformHydraError)?.['hydra:title']) {
                const err = error.response as ApiPlatformHydraError;
                if (err?.['hydra:description']) {
                    return `${err['hydra:title']} - ${err['hydra:description']}`;
                }

                return err['hydra:title'];
            }
        }

        if (typeof error === 'string') {
            return error;
        }
    }

    return 'error.default.unexpected';
}

export function getApiPlatformError(error: ApiError<ApiPlatformError | ApiPlatformHydraError>) {
    if (isApiPlatformHydraError(error)) {
        return {
            title: error.response['hydra:title'],
            detail: error.response['hydra:description'],
        };
    }

    const err = error.response as ApiPlatformError;

    return {
        title: err.title,
        detail: err.detail,
    };
}

export function mapApiPlatformViolationsToFormErrors(violations: ApiPlatformViolation[]): Record<string, string> {
    return Object.fromEntries(
        violations.map(({ propertyPath, message }) => [propertyPath, message]),
    );
}
