<template>
    <Button class="menu-button">
        <div>
            <span class="menu-button__icon" />
            <span class="menu-button__label">
                <Translate msg="appShell.navbar.menu" />
            </span>
        </div>
    </Button>
</template>

<style lang="scss" scoped>
.menu-button {
    --button-bg: var(--color-white);
    --button-border-color: var(--color-white);
    --button-color: var(--color-gray-coal-1);
    --button-size: .95rem;
    --button-height: 4.8rem;
    --button-hover-color: var(--color-primary-dark);
    --button-hover-bg: var(--button-bg);
    --button-active-bg: var(--button-bg);
    --button-active-color: var(--button-color);

    position: relative;

    inline-size: var(--button-height);
    padding: 0;

    border-radius: 100%;
    box-shadow: 0 .2rem .8rem 0 color-mix(in srgb, var(--color-black) 10%, transparent);

    transition: box-shadow .15s ease, color .15s ease;

    &:active {
        box-shadow: 0 .1rem .4rem 0 color-mix(in srgb, var(--color-black) 10%, transparent);
    }
}

.menu-button__icon {
    content: ' ';

    position: relative;

    display: block;

    inline-size: 100%;
    block-size: 2px;
    margin-block: .6rem;

    background: currentcolor;
    border-radius: 2px;

    &::before,
    &::after {
        content: ' ';

        position: absolute;
        inset-inline: 0;

        block-size: 2px;

        background: currentcolor;
        border-radius: 2px;
    }

    &::before {
        inset-block-start: -.5rem;
    }

    &::after {
        inset-block-end: -.5rem;
    }
}

.menu-button__label {
    text-transform: uppercase;
}
</style>
