import { createRouter, createWebHistory, type LocationQueryRaw } from 'vue-router';
import { Routes } from './routeConfig';
import { anonymousOnlyGuard, requiresAuthGuard } from './guards';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: Routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (requiresAuthGuard(to, from, next)) {
        return;
    }
    if (anonymousOnlyGuard(to, from, next)) {
        return;
    }
    next();
});

export default router;

export function updateRouteQuery(query: LocationQueryRaw) {
    const { currentRoute } = router;

    router.push({ query: {
        ...currentRoute.value.query,
        ...query,
    } });
}
