import { onMounted, ref } from 'vue';
import AuthService from '@/services/AuthService';
import type { Challenge } from '@/types/auth';
import { decodeSignedData, encodeChallenge } from '@/utils/encodeDecode';
import { useSimpleUserAuth } from '@/utils/dev';
import { useAuthStore } from '@/stores';

const CHROME_EXTENSION_ID = 'djjkpojddjhhiokkfdaalbaacfdfaaoo';

/**
 * A composable function that handles Chrome device authentication.
 *
 * This composable provides functionality to verify a device using the Chrome Verified Access API.
 * It retrieves the device serial number from a custom Chrome extension and sends a challenge
 * to the extension for verification. It also handles the verification of the challenge
 * response from the API.
 *
 *  @return An object containing reactive properties:
 *  - `deviceSerialNumber` - the serial number of the device
 *  - `isVerifiedDevice` - a boolean indicating whether the device is verified
 *  - `isPending` - a boolean indicating whether the verification is pending
 *  - `verifyDevice` - a function which try to verify the device
 */
export function useChromeDeviceAuth() {
    const isVerifiedDevice = ref(false);
    const isPending = ref(false);
    const errorMessage = ref();
    const deviceSerialNumber = ref('');
    const authStore = useAuthStore();

    function verifyDevice() {
        isPending.value = true;

        AuthService.getVerifiedAccessChallenge({ identifier: deviceSerialNumber.value })
            .then((response) => {
                return response.challenge;
            })
            .then((challenge: Challenge) => {
                sendChallengeToChromeExtension(encodeChallenge(challenge));
            })
            .catch(() => {
                errorMessage.value = 'error.chromeDeviceAuth.verify';
                isPending.value = false;
            });
    }

    function challengeResponseCallback(challenge: string) {
        const signedData = decodeSignedData(challenge);
        if (typeof signedData === 'string') {
            return;
        }

        AuthService.verifyAccessChallenge({
            challenge: signedData,
            identifier: deviceSerialNumber.value,
        })
            .then((response) => {
                authStore.deviceLoginData = response;
            })
            .catch(() => {
                authStore.deviceLoginData = null;
                errorMessage.value = 'error.chromeDeviceAuth.verify';
            })
            .finally(() => {
                isPending.value = false;
            });
    }

    function getDeviceSerialNumber() {
        if (useSimpleUserAuth()) {
            deviceSerialNumber.value = import.meta.env.VITE_FIXED_DEVICE_IDENTIFIER;
            console.log('Using fixed device identifier:', deviceSerialNumber.value);
        } else {
            try {
                chrome.runtime.sendMessage(
                    CHROME_EXTENSION_ID,
                    { message: 'getDeviceSerialNumber' },
                    (serialNumber: string) => {
                        deviceSerialNumber.value = serialNumber;

                        if (serialNumber === undefined) {
                            errorMessage.value = 'error.chromeDeviceAuth.deviceSerialNumber';
                        }
                    },
                );
            } catch {
                errorMessage.value = 'error.chromeDeviceAuth.verify';
            }
        }
    }

    function sendChallengeToChromeExtension(challenge: string) {
        chrome.runtime.sendMessage(
            CHROME_EXTENSION_ID,
            { challenge },
            challengeResponseCallback,
        );
    }

    onMounted(() => {
        getDeviceSerialNumber();
    });

    return {
        deviceSerialNumber,
        isVerifiedDevice,
        isPending,
        errorMessage,
        verifyDevice,
        getDeviceSerialNumber,
    };
}
