import { get } from '@/utils/requestWrapper';
import type {
    GetProductVariantsSearchParams,
    ProductVariantsSearch,
} from '@/types/product';

const URL = {
    PRODUCT_VARIANTS_SEARCH: '/shop/product-variants/search',
};

function getProductVariantsSearch({ signal, ...params }: GetProductVariantsSearchParams) {
    return get<ProductVariantsSearch[]>({
        url: URL.PRODUCT_VARIANTS_SEARCH,
        params,
        signal,
    });
}

export default {
    getProductVariantsSearch,
};
