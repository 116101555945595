<script setup lang="ts">
import { useI18n } from 'vue-i18n';

interface Props {
    id: string;
    label: string;
    error?: string;
    hideLabel?: boolean;
    optional?: boolean;
    optionalText?: string;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
    <div
        class="form-group"
        :class="{ '-has-error': error }"
    >
        <slot name="labelSlot">
            <label
                class="form-group__label"
                :class="{ '-invisible': hideLabel }"
                :for="id"
            >
                {{ label }}
                <span
                    v-if="optional"
                    class="form-group__label-optional"
                >
                    {{ optionalText || t('general.input.optional') }}
                </span>
            </label>
        </slot>
        <slot v-bind="{ id, label }" />
        <slot name="errorSlot">
            <p
                v-if="error"
                class="form-group__error"
                :id="`${id}-error`"
                aria-live="assertive"
            >
                <SvgIcon name="error" />
                {{ error }}
            </p>
        </slot>
    </div>
</template>

<style lang="scss" scoped>
.form-group {
    position: relative;
    margin-block-end: 2rem;
}

.form-group__label {
    display: block;
    padding-block-end: 1.2rem;
    font-size: 1.6rem;
    line-height: 1;

    &.-invisible {
        @extend %sr-only;
    }
}

.form-group__label-optional {
    display: inline-block;
    margin-inline-start: .3em;
    font-size: .75em;
}

.form-group__loader {
    position: absolute;
    inset-block-start: 1rem;
    inset-inline-end: 1rem;
}

.form-group__error {
    position: relative;

    margin-block: 1.6rem;
    margin-inline-start: .6rem;
    padding-inline-start: 1.8rem;

    font-size: 1.3rem;
    color: var(--color-danger);

    .svg-icon {
        position: absolute;
        inset-block-start: .4rem;
        inset-inline-start: 0;
    }
}
</style>
