<script setup lang="ts">
import { computed } from 'vue';
import { MENU_ITEMS } from '@/config/menu.config';

const gridSize = computed(() => {
    return MENU_ITEMS.length >= 4 ? 4 : MENU_ITEMS.length;
});
</script>

<template>
    <h1 class="title">
        <Translate msg="menu.title" />
    </h1>
    <nav class="main-nav">
        <ul
            class="main-nav__list"
            :style="{ gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))` }"
        >
            <li
                v-for="item in MENU_ITEMS"
                :key="item.translationKey"
            >
                <MenuTile
                    :route-name="item.routeName"
                    :translation-key="item.translationKey"
                    :svg="item.svg"
                />
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
.title {
    margin: 0;
    font-size: 3rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
}

.main-nav {
    padding-inline: 4rem;
}

.main-nav__list {
    @extend %reset-list;

    display: grid;
    gap: 6rem;
    align-items: stretch;
    margin: 5rem 0 4rem;
}
</style>
