<script setup lang="ts">
import { useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { d } = useI18n();
const { lastLogin } = storeToRefs(authStore);
const cachedLastLogin = lastLogin.value;
</script>

<template>
    <div
        v-if="cachedLastLogin"
        class="last-login"
    >
        <div class="last-login__title">
            <Translate msg="views.login.lastLogin" />
        </div>
        <div class="last-login__label">
            <SvgIcon
                name="user2"
                class="last-login__label-icon"
            />
            <span>{{ cachedLastLogin.name }}</span>
            <span> - </span>
            <span>{{ d(cachedLastLogin.date as Date, 'long').split('-').join('/') }}</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.last-login {
    display: inline-block;
}

.last-login__title {
    margin-block-end: .2rem;
}

.last-login__label {
    position: relative;
    color: var(--color-chimmichurri);

    span + span {
        margin-inline-start: .5rem;
    }
}

.last-login__label-icon {
    display: inline-block;
    margin-block-end: .2rem;
    margin-inline-end: .8rem;
    vertical-align: middle;
}
</style>
