import { get, post, put } from '@/utils/requestWrapper';
import type {
    AddAddressPayload,
    AddCustomerPayload,
    Customer,
    CustomerWithAddress,
    GetAddressSearchParams,
    GetCustomerSearchParams, CustomerSearchResult, UpdateCustomerPayload,
} from '@/types/customer';
import { DEFAULT_HEADERS, HEADER_NAME } from '@/config/api.config';
import type { WithContext } from '@/types/api';

const URL = {
    ADDRESSES: '/shop/addresses',
    ADDRESSES_DETAIL: '/shop/addresses/:id',
    ADDRESSES_SEARCH: '/pos/addresses/search',
    CUSTOMERS: '/pos/customers',
    CUSTOMERS_SEARCH: '/pos/customers/search',
    CUSTOMER_DETAIL: '/shop/customers/:id',
};

function getCustomers({ signal, ...params }: GetCustomerSearchParams) {
    return get<CustomerSearchResult[]>({
        url: URL.CUSTOMERS_SEARCH,
        params,
        signal,
    });
}

function getCustomerDetail(id: number) {
    return get<Customer>({
        url: URL.CUSTOMER_DETAIL,
        pathParams: {
            id,
        },
    });
}

function getAddresses({ signal, ...params }: GetAddressSearchParams) {
    return get<CustomerWithAddress[]>({
        url: URL.ADDRESSES_SEARCH,
        params,
        signal,
    });
}

function getAddressDetail(id: number) {
    return get<CustomerWithAddress>({
        url: URL.ADDRESSES_DETAIL,
        pathParams: {
            id,
        },
    });
}

function updateAddress({ id, ...body }: CustomerWithAddress) {
    return put<CustomerWithAddress>({
        url: URL.ADDRESSES_DETAIL,
        pathParams: {
            id,
        },
        body: body,
    });
}

function addCustomer(payload: AddCustomerPayload) {
    return post({
        url: URL.CUSTOMERS,
        body: payload,
    });
}

function updateCustomer({ id, ...body }: UpdateCustomerPayload) {
    return put<Customer>({
        url: URL.CUSTOMER_DETAIL,
        pathParams: {
            id,
        },
        body: body,
    });
}

function addAddress(payload: AddAddressPayload) {
    return post<CustomerWithAddress & WithContext>({
        url: URL.ADDRESSES,
        body: payload,
        headers: {
            ...DEFAULT_HEADERS,
            [HEADER_NAME.ACCEPT]: 'application/ld+json',
        },
    });
}

export default {
    getCustomers,
    getCustomerDetail,
    addCustomer,
    updateCustomer,
    getAddresses,
    getAddressDetail,
    updateAddress,
    addAddress,
};
