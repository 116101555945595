import 'normalize.css';
import './assets/scss/global.scss';
import 'primevue/resources/themes/aura-light-green/theme.css';

import { createApp } from 'vue';

import App from './App.vue';
import router from '@/router';
import { i18n } from '@/config/i18n.config';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import store from '@/stores';
import { initErrorReporting } from '@/utils/errorReporter';

export const app = createApp(App);

initErrorReporting({ app, router });

app.use(store);
app.use(router);
app.use(i18n);
app.use(PrimeVue, { unstyled: true });
app.use(ToastService);

app.mount('#app');
