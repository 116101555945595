import ToastEventBus from 'primevue/toasteventbus';
import type { ToastMessageOptions } from 'primevue/toast';

export const useToastService = () => {
    const add = (options: ToastMessageOptions) => {
        ToastEventBus.emit('add', {
            life: 5000,
            ...options,
        });
    };

    return { add };
};
