import { StatusCodes } from 'http-status-codes';

export const ERROR_STATUS_NOT_TO_REPORT: number[] = [
    StatusCodes.UNAUTHORIZED,
];

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENABLED = typeof SENTRY_DSN === 'string' && SENTRY_DSN.length > 0;
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_ENV;

export const SENTRY_RATES = {
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
};
