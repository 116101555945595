export const API_URL = {
    BASE: import.meta.env.VITE_API_URL,
    MOCK: '/mock/api',
};

export const DEFAULT_TIMEOUT_IN_MILLIS = 25 * 1000;

export const HEADER_NAME = {
    ACCEPT: 'Accept',
    ACCEPT_LANGUAGE: 'Accept-Language',
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'Content-Type',
    PDF_HEIGHT: 'X-Pdf-Height',
};

export const DEFAULT_HEADERS = {
    [HEADER_NAME.ACCEPT]: 'application/json',
    [HEADER_NAME.ACCEPT_LANGUAGE]: 'nl',
    [HEADER_NAME.CONTENT_TYPE]: 'application/json',
};
