import nl from '@/translations/nl.yaml';
import { createI18n } from 'vue-i18n';
import { setLocale } from 'yup';
import { nl as nlYup } from 'yup-locales';

export const i18n = createI18n({
    legacy: false,
    locale: 'nl',
    messages: {
        nl: nl,
    },
    numberFormats: {
        nl: {
            currency: {
                style: 'currency', currency: 'EUR', notation: 'standard', currencyDisplay: 'symbol',
            },
            decimal: {
                style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
            },
            percent: {
                style: 'percent', useGrouping: false,
            },
        },
    },
    datetimeFormats: {
        nl: {
            long: {
                year: 'numeric', month: '2-digit', day: '2-digit',
                hour: '2-digit', minute: '2-digit',
            },
            date: {
                year: 'numeric', month: '2-digit', day: '2-digit',
            },
        },
    },
});

setLocale(
    {
        ...nlYup,
        mixed: {
            required: ({ path }) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return (i18n.global as any).t('general.input.validation.required', { field: path });
            },
        },
    },
);
