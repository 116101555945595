<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

const timeout = ref<number>();
const wrap = ref<HTMLDivElement>();
const scrollContainer = ref<HTMLDivElement>();
const boxSize = ref<{ width?: number, height?: number }>({
    width: undefined,
    height: undefined,
});
const shadowVisible = ref({
    top: false,
    right: false,
    bottom: false,
    left: false,
});

function calcDimensions() {
    if (timeout.value) {
        window.cancelAnimationFrame(timeout.value);
    }

    timeout.value = window.requestAnimationFrame(async () => {
        boxSize.value.width = undefined;
        boxSize.value.height = undefined;
        await nextTick();
        boxSize.value.width = wrap.value ? Math.ceil(wrap.value?.getBoundingClientRect().width) : undefined;
        boxSize.value.height = wrap.value ? Math.ceil(wrap.value?.getBoundingClientRect().height) : undefined;
    });
}

function scrollToTop() {
    if (scrollContainer.value) {
        scrollContainer.value.scrollTop = 0;
    }
}

function toggleShadow() {
    if (scrollContainer.value) {
        const hasHorizontalScrollbar = scrollContainer.value?.clientWidth < scrollContainer.value?.scrollWidth;
        const hasVerticalScrollbar = scrollContainer.value?.clientHeight < scrollContainer.value?.scrollHeight;
        const scrollFromLeft = scrollContainer.value?.offsetWidth + scrollContainer.value?.scrollLeft;
        const scrollFromTop = scrollContainer.value?.offsetHeight + Math.ceil(scrollContainer.value?.scrollTop);

        const scrolledToTop = scrollContainer.value?.scrollTop === 0;
        const scrolledToRight = scrollFromLeft >= scrollContainer.value?.scrollWidth;
        const scrolledToBottom = scrollFromTop >= scrollContainer.value?.scrollHeight;
        const scrolledToLeft = scrollContainer.value?.scrollLeft === 0;

        shadowVisible.value.top = hasVerticalScrollbar && !scrolledToTop;
        shadowVisible.value.right = hasHorizontalScrollbar && !scrolledToRight;
        shadowVisible.value.bottom = hasVerticalScrollbar && !scrolledToBottom;
        shadowVisible.value.left = hasHorizontalScrollbar && !scrolledToLeft;
    }
}

let wrapObserver: undefined | ResizeObserver;
let scrollContainerObserver: undefined | ResizeObserver;

onMounted(() => {
    wrapObserver = new ResizeObserver(calcDimensions);
    if (wrapObserver && wrap.value) {
        wrapObserver.observe(wrap.value);
    }

    scrollContainerObserver = new ResizeObserver(toggleShadow);
    if (scrollContainerObserver && scrollContainer.value) {
        scrollContainerObserver.observe(scrollContainer.value);
    }
});

onUnmounted(() => {
    wrapObserver?.disconnect();
    scrollContainerObserver?.disconnect();
});

defineExpose({ calcDimensions, scrollToTop });
</script>

<template>
    <div
        ref="wrap"
        class="scroll-container"
    >
        <div
            ref="scrollContainer"
            class="scroll-container__scroll"
            :class="{
                ['-vertical']: shadowVisible.top || shadowVisible.bottom,
                ['-horizontal']: shadowVisible.left || shadowVisible.right,
            }"
            :style="{
                width: boxSize.width ? `${boxSize.width}px` : 'auto',
                height: boxSize.height ? `${boxSize.height}px` : 'auto',
            }"
            @scroll.passive="toggleShadow"
        >
            <slot />
            <span
                class="scroll-container__shadow -top"
                :class="{'-visible': shadowVisible.top}"
            />
            <span
                class="scroll-container__shadow -right"
                :class="{'-visible': shadowVisible.right}"
            />
            <span
                class="scroll-container__shadow -bottom"
                :class="{'-visible': shadowVisible.bottom}"
            />
            <span
                class="scroll-container__shadow -left"
                :class="{'-visible': shadowVisible.left}"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.scroll-container {
    --sc-shadow-size: 5rem;

    position: relative;
    overflow: hidden;
}

.scroll-container__scroll {
    overflow: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }

    &::-webkit-scrollbar {
        inline-size: .7rem;
        appearance: none;
        background-color: var(--color-gray-cool-8);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray-cool-3);
        box-shadow: 0 0 1px rgb(255 255 255 / 50%);
    }

    &.-vertical {
        padding-inline-end: 1.7rem;
    }
}

.scroll-container__shadow {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    transition: opacity .5s ease;

    &.-visible {
        opacity: 1;
    }

    &.-top,
    &.-bottom {
        inset-inline: 0;
        block-size: var(--sc-shadow-size);
        background-image: linear-gradient(rgba(158 158 158 / 10%) 0%, rgba(255 255 255 / 0%) 100%);
    }

    &.-left,
    &.-right {
        inset-block: 0;
        inline-size: var(--sc-shadow-size);
        background-image: linear-gradient(90deg, rgba(158 158 158 / 10%) 0%, rgba(255 255 255 / 0%) 100%);
    }

    &.-top {
        inset-block-start: 0;
    }

    &.-bottom {
        inset-block-end: 0;
        transform: rotate(180deg);
    }

    &.-left {
        inset-inline-start: 0;
    }

    &.-right {
        inset-inline-end: 0;
        transform: rotate(180deg);
    }
}
</style>
