import { computed, reactive, ref, shallowRef } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type { LastLogin, LoginData } from '@/types/auth';
import AuthService from '@/services/AuthService';
import { useRouter } from 'vue-router';
import { RouteNames } from '@/router/routeNames';
import { type AsyncEntity, AsyncStatus } from '@/types/api';
import { asyncFetchHandler, resetAsyncEntity } from '@/utils/asyncEntity';
import { useNewSaleStore, useUIStore } from '@/stores';
import LogoutModal from '@/views/AppShell/LogoutModal.vue';
import { useSimpleUserAuth } from '@/utils/dev';
import { CheckoutState } from '@/types/order';
import type { PosUnit, Store } from '@/types/common';
import AdministrationService from '@/services/AdministrationService';

export const useAuthStore = defineStore('auth', () => {
    const router = useRouter();
    const newSaleStore = useNewSaleStore();
    const { order } = storeToRefs(newSaleStore);

    const loginData: AsyncEntity<LoginData> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const logoutData: AsyncEntity<never> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const posUnitDetail: AsyncEntity<PosUnit> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const storeDetail: AsyncEntity<Store> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const deviceLoginData = ref<LoginData | null>();
    const lastLogin = ref<LastLogin | null>();
    const isLogoutDisabled = ref(false);
    const isLoggedIn = computed(() => !!loginData.data);

    function login({ identifier, challenge }: { identifier: string; challenge: string }) {
        if (useSimpleUserAuth()) {
            return asyncFetchHandler({
                asyncEntity: loginData,
                service: AuthService.simpleUserAuth,
            })({ identifier, challenge })
                .then((data) => {
                    if (data instanceof Error) {
                        resetAsyncEntity(logoutData);
                        return;
                    }

                    getStore(data.store);
                    updateLastLogin(data.name);
                });
        }

        const token = deviceLoginData.value?.token || '';
        return asyncFetchHandler({
            asyncEntity: loginData,
            service: AuthService.userAuth,
        })({ challenge, token })
            .then((data) => {
                if (data instanceof Error) {
                    resetAsyncEntity(logoutData);
                    return;
                }

                getStore(data.store);
                updateLastLogin(data.name);
            });
    }

    function logout() {
        const uiStore = useUIStore();

        if (isLogoutDisabled.value || order.value.data?.checkoutState === CheckoutState.PaymentSelected) {
            uiStore.openModal({
                component: shallowRef(LogoutModal),
                props: {
                    fullscreen: true,
                    disableManuallyClose: true,
                    width: 'narrow',
                },
            });

            return;
        }

        handleActualLogout();
    }

    function forceLogout() {
        handleActualLogout();
    }

    function handleActualLogout() {
        asyncFetchHandler({
            asyncEntity: logoutData,
            service: AuthService.logout,
        })({});
        resetAsyncEntity(loginData);
        resetAsyncEntity(storeDetail);
        useUIStore().closeModal();
        router.push({ name: RouteNames.Login });
    }

    function updateLastLogin(name: string) {
        lastLogin.value = {
            name,
            date: new Date(),
        };
    }

    function enableLogout() {
        isLogoutDisabled.value = false;
    }

    function disableLogout() {
        isLogoutDisabled.value = true;
    }

    function getPosUnit(id: string) {
        return asyncFetchHandler({
            asyncEntity: posUnitDetail,
            service: AdministrationService.getPosUnitDetail,
        })({ id });
    }

    function getStore(id: string) {
        return asyncFetchHandler({
            asyncEntity: storeDetail,
            service: AdministrationService.getStoreDetail,
        })({ id });
    }

    return {
        loginData, isLoggedIn, login, logout, forceLogout,
        lastLogin, deviceLoginData,
        isLogoutDisabled, enableLogout, disableLogout,
        posUnitDetail, getPosUnit, storeDetail,
    };
}, {
    persist: true,
});
