import type {
    VerifyAccessChallengePayload,
    LoginData,
    GetVerifiedAccessChallengeResponse,
    GetVerifiedAccessChallengePayload,
    SimpleAuthUserLoginPayload,
    UserLoginPayload,
} from '@/types/auth';
import { get, post } from '@/utils/requestWrapper';
import { DEFAULT_HEADERS, HEADER_NAME } from '@/config/api.config';

export const URL = {
    LOGIN_PREVIOUS: '/login/previous',
    VERIFIED_ACCESS_CHALLENGE: '/pos/challenge',
    VERIFIED_ACCESS_LOGIN: '/pos/login',
    VERIFIED_ACCESS_VERIFY: '/pos/verify',
    LOGIN: '/pos/login',
    LOGOUT: '/pos/logout',
};

function getVerifiedAccessChallenge({ identifier }: GetVerifiedAccessChallengePayload) {
    return post<GetVerifiedAccessChallengeResponse>({
        url: URL.VERIFIED_ACCESS_CHALLENGE,
        body: {
            identifier,
        },
    });
}

function verifyAccessChallenge({ identifier, challenge }: VerifyAccessChallengePayload) {
    return post<LoginData>({
        url: URL.VERIFIED_ACCESS_VERIFY,
        body: {
            identifier,
            challenge,
        },
    });
}

function simpleUserAuth({ identifier, challenge }: SimpleAuthUserLoginPayload) {
    return post<LoginData>({
        url: URL.LOGIN,
        body: {
            identifier,
            challenge,
        },
        addAuthorizationHeader: false,
    });
}

function userAuth({ challenge, token }: UserLoginPayload) {
    return post<LoginData>({
        url: URL.LOGIN,
        body: {
            challenge,
        },
        addAuthorizationHeader: false,
        headers: {
            ...DEFAULT_HEADERS,
            [HEADER_NAME.AUTHORIZATION]: `Bearer ${token}`,
        },
    });
}

function logout() {
    return get({
        url: URL.LOGOUT,
    });
}

export default {
    getVerifiedAccessChallenge,
    verifyAccessChallenge,
    simpleUserAuth,
    userAuth,
    logout,
};
