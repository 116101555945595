<script setup lang="ts">
import { useI18n } from 'vue-i18n';

interface Props {
    msg: string;
    placeholders?: {
        [key: string]: string;
    };
}

defineProps<Props>();

const { t } = useI18n();

</script>

<template>{{ t(msg, placeholders || {}) }}</template>
