import { get, patch, post, remove, put } from '@/utils/requestWrapper';
import type {
    AddItemToOrderPayload,
    CancelOrderPayload,
    GetOrderDetailsPayload,
    GetPosSalesStatsPayload,
    NewOrder,
    Order,
    OrderAdjustment,
    Payment,
    PaymentPayload,
    PosSalesStat,
    RemoveItemFromOrderPayload,
    UpdateItemDiscountPayload,
    UpdateItemQuantityPayload,
    UpdateItemVoucherDetailsPayload,
    UpdateItemWishlistPayload,
    UpdateOrderPayload,
} from '@/types/order';

const URL = {
    ORDERS: '/pos/orders',
    ORDER_NEW: '/shop/orders',
    ORDER_DETAIL: '/shop/orders/:tokenValue',
    ORDER_CANCEL: '/pos/orders/:tokenValue/cancel',
    ORDER_ITEMS: '/shop/orders/:tokenValue/items',
    ORDER_ITEM: '/shop/orders/:tokenValue/items/:orderItemId',
    ORDER_ITEM_DISCOUNT: '/pos/orders/:tokenValue/items/:orderItemId/discount',
    ORDER_ITEM_WISHLIST: '/shop/orders/:tokenValue/items/:orderItemId/wishlist',
    ORDER_ADJUSTMENTS: '/shop/orders/:tokenValue/adjustments',
    VOUCHER_DETAILS: '/shop/orders/:tokenValue/items/:orderItemId/voucher-details',
    PAYMENT: '/pos/payments',
    POS_SALES_STATS: '/pos/sales-stats',
};

function createNewOrder() {
    return post<NewOrder>({
        url: URL.ORDER_NEW,
    });
}

function getOrderDetail({ tokenValue }: GetOrderDetailsPayload) {
    return get<Order>({
        url: URL.ORDER_DETAIL,
        pathParams: {
            tokenValue,
        },
    });
}

function cancelOrder({ tokenValue }: CancelOrderPayload) {
    return patch<Order>({
        url: URL.ORDER_CANCEL,
        pathParams: {
            tokenValue,
        },
    });
}

function addItemToOrder({ tokenValue, productVariant, quantity = 1 }: AddItemToOrderPayload) {
    return post<Order>({
        url: URL.ORDER_ITEMS,
        pathParams: {
            tokenValue,
        },
        body: {
            productVariant,
            quantity,
        },
    });
}

function deleteItemFromOrder({ tokenValue, orderItemId }: RemoveItemFromOrderPayload) {
    return remove<Order>({
        url: URL.ORDER_ITEM,
        pathParams: {
            tokenValue,
            orderItemId,
        },
    });
}

function updateItemQuantity({ tokenValue, orderItemId, quantity }: UpdateItemQuantityPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            quantity,
        },
    });
}

function updateItemDiscount({ tokenValue, orderItemId, discount }: UpdateItemDiscountPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM_DISCOUNT,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            percentage: discount,
        },
    });
}

function updateItemWishlist({ tokenValue, orderItemId, wishlistCode }: UpdateItemWishlistPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM_WISHLIST,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            wishlistCode,
        },
    });
}

function updateVoucherDetail({ tokenValue, orderItemId, voucherDetails }: UpdateItemVoucherDetailsPayload) {
    return patch<Order>({
        url: URL.VOUCHER_DETAILS,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            ...voucherDetails,
            expiresAt: typeof voucherDetails.expiresAt === 'string'
                ? voucherDetails.expiresAt
                : voucherDetails.expiresAt.toISOString(),
        },
    });
}

function updateOrder({ tokenValue, ...body }: UpdateOrderPayload) {
    return put<Order>({
        url: URL.ORDER_DETAIL,
        pathParams: {
            tokenValue,
        },
        body,
    });
}

function getOrderAdjustments({ tokenValue }: GetOrderDetailsPayload) {
    return get<OrderAdjustment[]>({
        url: URL.ORDER_ADJUSTMENTS,
        pathParams: {
            tokenValue,
        },
    });
}

function createPayment<T>(body: PaymentPayload<T>) {
    return post<Payment>({
        url: URL.PAYMENT,
        body,
    });
}

function getPosSalesStats(params: GetPosSalesStatsPayload) {
    return get<PosSalesStat[]>({
        url: URL.POS_SALES_STATS,
        params,
    });
}

export default {
    createNewOrder,
    getOrderDetail,
    cancelOrder,
    addItemToOrder,
    deleteItemFromOrder,
    updateItemQuantity,
    updateItemDiscount,
    updateItemWishlist,
    updateOrder,
    getOrderAdjustments,
    updateVoucherDetail,
    createPayment,
    getPosSalesStats,
};
