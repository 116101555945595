<script lang="ts" setup>
import { withDefaults, watch, computed } from 'vue';
import NavBar from '@/views/AppShell/NavBar.vue';
import type { LayoutContentSize } from '@/types/router';
import { useAuthStore, useUIStore } from '@/stores';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { RouteNames } from '@/router/routeNames';
import { useRoute } from 'vue-router';

interface Props {
    contentSize?: LayoutContentSize;
    disableMenu?: boolean;
    hideNavBar?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    contentSize: 'full',
});

const authStore = useAuthStore();
const uiStore = useUIStore();
const route = useRoute();
const {
    slideOutPanelState, openSlideOutPanel, closeSlideOutPanel, onCloseIntentSlideOutPanel,
    onAfterCloseSlideOutPanel, onAfterOpenSlideOutPanel,
} = useUIStore();
const { loginData, storeDetail } = storeToRefs(authStore);
const { isMenuDisabled } = storeToRefs(uiStore);

const disableMenuButton = computed(() => props.disableMenu || isMenuDisabled.value);

const onMenuClick = () => {
    router.push({ name: RouteNames.Menu });
};

watch(
    () => route.meta,
    () => {
        if (route.meta?.slideOutPanel) {
            openSlideOutPanel();
        } else if (slideOutPanelState.isOpen) {
            closeSlideOutPanel();
        }
    },
    { immediate: true },
);
</script>

<template>
    <div
        class="default-layout"
        :class="`-${contentSize}`"
    >
        <div class="default-layout__inner">
            <header class="default-layout__header">
                <NavBar
                    v-if="!hideNavBar"
                    :disable-menu="disableMenuButton"
                    @click:menu="onMenuClick"
                    @logout="authStore.logout"
                    :user="loginData.data?.name || null"
                    :store-name="storeDetail.data?.name || null"
                    :pos-unit-name="loginData.data?.unit || null"
                />
            </header>
            <div class="default-layout__bottom">
                <main
                    data-modal-wrapper
                    class="default-layout__content"
                >
                    <div class="default-layout__scroll-container">
                        <div class="default-layout__scroll-container-inner">
                            <slot />
                        </div>
                    </div>
                    <SlideOutPanel
                        :is-open="slideOutPanelState.isOpen"
                        :on-close-intent="onCloseIntentSlideOutPanel"
                        :on-after-open="onAfterOpenSlideOutPanel"
                        :on-after-close="onAfterCloseSlideOutPanel"
                        class="default-layout__aside"
                    />
                </main>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.default-layout {
    block-size: 100%;
    margin: 0;
    padding: 5rem 0;
}

.default-layout__inner {
    display: grid;
    grid-template-areas:
        'header'
        'content';
    grid-template-rows: auto minmax(0, 1fr);

    inline-size: 176rem;
    max-inline-size: calc(100% - 5rem);
    block-size: 100%;
    max-block-size: 100%;
    margin: 0 auto;

    /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
    transition: inline-size .3s ease;

    .-small & {
        inline-size: 64rem;
    }

    .-medium & {
        inline-size: 144rem;
    }
}

.default-layout__header {
    grid-area: header;
}

.default-layout__bottom {
    display: flex;
    grid-area: content;
    align-items: center;
}

.default-layout__content {
    position: relative;
    z-index: 1;

    overflow: hidden;
    flex: 1 1 auto;

    inline-size: 100%;
    block-size: 100%;
    max-block-size: 100%;
    padding: 3rem 1.5rem;

    background-color: var(--color-white);
    border-radius: 3rem;
    box-shadow: 0 .2rem 2rem 0 #C5C6C7;

    .-small &,
    .-medium & {
        block-size: auto;
    }
}

.default-layout__scroll-container {
    overflow: auto;
    block-size: 100%;
    max-block-size: 100%;
}

.default-layout__scroll-container-inner {
    block-size: 100%;
    padding-inline: 1.5rem;
}

.default-layout__aside {
    position: absolute;
    z-index: 2;
    inset: 0;
}
</style>
