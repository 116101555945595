import { get } from '@/utils/requestWrapper';
import type { GetWishlistSearchParams, Wishlist } from '@/types/wishlist';

const URL = {
    WISHLISTS: '/shop/wishlists',
};

function getWishlists({ signal, ...params }: GetWishlistSearchParams) {
    return get<Wishlist[]>({
        url: URL.WISHLISTS,
        params,
        signal,
    });
}

export default {
    getWishlists,
};
