<script lang="ts" setup>
import { useUIStore } from '@/stores';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { TELEPORT_ITEMS } from '@/config/ui.config';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

export interface BaseModalProps {
    title?: string;
    fullscreen?: boolean;
    disableManuallyClose?: boolean;
    disableFocusTrap?: boolean;
    width?: 'narrow' | 'medium' | 'wide' | 'default';
    showCloseButton?: boolean;
    afterClose?: () => void;
}

const uiStore = useUIStore();
const { modalProps } = storeToRefs(uiStore);
const { modalState, closeModal } = uiStore;
const modalRef = ref<HTMLElement>();
const scrollContainerRef = ref();
const modalContentRef = ref();
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(modalRef);

function keyDownListener(event: KeyboardEvent) {
    if (event.key === 'Escape') {
        if (!modalProps.value?.disableManuallyClose) {
            closeModal();
        }
    }
}

function onAfterEnter() {
    if (!modalProps.value?.disableFocusTrap) {
        activateFocusTrap();
    }
}

function onAfterLeave() {
    if (!modalProps.value?.disableFocusTrap) {
        deactivateFocusTrap();
    }
}

function calcDimensions() {
    if (scrollContainerRef.value) {
        scrollContainerRef.value.calcDimensions();
    }
}

let modalContentObserver: undefined | MutationObserver;
let modalResizeObserver: undefined | ResizeObserver;

watch(() => modalContentRef.value, () => {
    if (modalContentObserver && modalContentRef.value) {
        modalContentObserver.observe(modalContentRef.value, {
            childList: true,
            subtree: true,
        });
    }
}, { immediate: true });

watch(() => modalRef.value, () => {
    if (modalResizeObserver && modalRef.value) {
        modalResizeObserver.observe(modalRef.value);
    }
}, { immediate: true });

onMounted(() => {
    document.addEventListener('keydown', keyDownListener);

    modalContentObserver = new MutationObserver(calcDimensions);
    modalResizeObserver = new ResizeObserver(calcDimensions);
});

onUnmounted(() => {
    document.removeEventListener('keydown', keyDownListener);

    modalContentObserver?.disconnect();
    modalResizeObserver?.disconnect();
});
</script>

<template>
    <Teleport :to="TELEPORT_ITEMS.modal">
        <Transition
            name="modal-fade"
            @after-enter="onAfterEnter"
            @after-leave="onAfterLeave"
        >
            <div
                class="modal"
                :class="{
                    '-fullscreen': modalProps?.fullscreen,
                    [`-${modalProps?.width}`]: !!modalProps?.width,
                }"
                v-if="modalState?.component"
                aria-modal="true"
                role="dialog"
                tabindex="-1"
                ref="modalRef"
            >
                <div class="modal__inner">
                    <IconButton
                        v-if="modalProps?.showCloseButton && !modalProps?.disableManuallyClose"
                        icon-name="cross"
                        class="modal__close-button"
                        @click="closeModal"
                        tabindex="-1"
                    />
                    <h4
                        v-if="modalProps?.title"
                        class="modal__title"
                    >
                        {{ modalProps.title }}
                    </h4>
                    <ScrollContainer ref="scrollContainerRef">
                        <div ref="modalContentRef">
                            <component
                                :is="modalState?.component"
                                v-bind="modalState?.props"
                            />
                        </div>
                    </ScrollContainer>
                </div>
                <div
                    class="modal__backdrop"
                    @click.self="!modalProps?.disableManuallyClose ? closeModal() : undefined"
                />
            </div>
        </Transition>
    </Teleport>
</template>

<style lang="scss" scoped>
.modal {
    --modal-width: 64rem;
    --modal-border-radius: 3rem;

    position: absolute;
    z-index: var(--z-index-modal);
    inset-block-start: 0;
    inset-inline-start: 0;

    display: grid;
    place-items: center;

    inline-size: 100%;
    block-size: 100%;

    backdrop-filter: blur(.3rem);

    &.-fullscreen {
        position: fixed;
        inline-size: 100vw;
        block-size: 100vh;

        .modal__backdrop {
            opacity: .7;
            background-color: var(--color-gray-cool-2);
        }
    }

    &.-narrow {
        --modal-width: 50rem;
    }

    &.-medium {
        --modal-width: 75rem;
    }

    &.-wide {
        --modal-width: 156rem;
    }
}

.modal__inner {
    position: relative;
    z-index: calc(var(--z-index-modal) + 1);

    overflow: hidden;
    display: flex;
    flex-direction: column;

    inline-size: var(--modal-width);
    max-inline-size: calc(100vw - 5rem);
    max-block-size: calc(100% - 5rem);
    padding: 4.4rem 3rem 3rem;

    background-color: var(--color-white);
    border-radius: var(--modal-border-radius);

    .-wide > & {
        max-inline-size: calc(100vw - 10rem);
    }
}

.modal__close-button {
    --button-bg: transparent;
    --button-hover-bg: transparent;
    --button-border-color: transparent;
    --button-color: var(--color-gray-cool-2);
    --button-hover-color: var(--color-gray-cool-1);

    position: absolute;
    inset-block-start: 1rem;
    inset-inline-end: 1rem;
}

.modal__title {
    flex: 0 0 auto;
    margin-block-start: 0;
    text-align: center;
}

// stylelint-disable-next-line no-descending-specificity
.modal__backdrop {
    position: absolute;
    z-index: calc(var(--z-index-modal) - 1);
    inset: 0;

    opacity: .4;
    background-color: var(--color-gray-cool-4);
}

.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: .25s ease opacity;
}
</style>
