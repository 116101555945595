<script setup lang="ts">
import SvgIcon, { type SvgIconName } from '@/components/SvgIcon.vue';
import { ref } from 'vue';

interface Props {
    type: 'error' | 'success' | 'warning' | 'notification';
}

const props = defineProps<Props>();

const iconName = ref<SvgIconName>('checkmark-round');

switch (props.type) {
    case 'notification':
        iconName.value = 'bell';
        break;
    case 'error':
    case 'warning':
        iconName.value = 'error';
        break;
    default:
        iconName.value = 'checkmark-round';
        break;
}

</script>

<template>
    <div
        class="alert"
        :class="{[`-${type}`]: type}"
    >
        <SvgIcon
            :name="iconName"
            class="alert__icon"
        />
        <div>
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.alert {
    --alert-font-size: 1.3rem;

    position: relative;

    padding: .2rem .8rem .2rem 2.5rem;

    font-size: var(--alert-font-size);
    color: var(--alert-color);

    background-color: var(--alert-bg);
    border: 1px solid var(--alert-border-color);
    border-radius: .3rem;

    &.-success {
        --alert-border-color: var(--color-greenie-2);
        --alert-color: var(--color-greenie-4);
        --alert-bg: var(--color-greenie-3);
    }

    &.-error {
        --alert-border-color: var(--color-blush-1);
        --alert-color: var(--color-danger);
        --alert-bg: var(--color-blush-2);
    }

    &.-warning {
        --alert-border-color: var(--color-warning);
        --alert-color: var(--color-gray-coal-3);
        --alert-bg: var(--color-honey-milk-light);
        --alert-icon-color: var(--color-gray-coal-4);
    }

    &.-notification {
        --alert-border-color: var(--color-cadet);
        --alert-color: var(--color-cadet-dark);
        --alert-bg: var(--color-seafoam-light);
        --alert-icon-color: var(--color-chimmichurri);
    }
}

.alert__icon {
    position: absolute;
    inset-block-start: .6rem;
    inset-inline-start: .8rem;
    color: var(--alert-icon-color);
}
</style>
