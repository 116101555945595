import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import type { NavigationGuardNext } from 'vue-router';

export function getCustomerDataIfNeeded(
    params: {
        customerId: string,
        addressId?: string,
        forceRefresh?: boolean,
    },
    next: NavigationGuardNext,
) {
    const { customerId, addressId, forceRefresh } = params;
    const customerStore = useCustomerStore();
    const { customerDetail, addressDetail } = storeToRefs(customerStore);

    const getCustomerDetailsIfNeeded = () => {
        if (forceRefresh
            || !customerDetail.value?.data?.id
            || customerDetail.value.data.id.toString() !== customerId
        ) {
            return customerStore.getCustomerDetail(parseInt(customerId, 10));
        }

        return Promise.resolve();
    };

    const getAddressDetailsIfNeeded = () => {
        if (addressId && (
            forceRefresh
            || !addressDetail.value?.data?.id
            || addressDetail.value.data.id.toString() !== addressId
        )) {
            return customerStore.getAddressDetail(parseInt(addressId, 10));
        }

        return Promise.resolve();
    };

    const promiseArray = [
        getCustomerDetailsIfNeeded(),
        getAddressDetailsIfNeeded(),
    ];

    Promise.allSettled(promiseArray).then(() => {
        next();
    });
}
