<script setup lang="ts">
import { RouteNames } from '@/router/routeNames';
import { getErrorMessageToShow } from '@/utils/api';
import { useAuthStore } from '@/stores';
import { AsyncStatus } from '@/types/api';
import { storeToRefs } from 'pinia';
import { useField, useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { validationSchema } from './loginform.schema';
import LastLogin from '@/views/Login/LastLogin.vue';
import { useChromeDeviceAuth } from '@/views/Login/chromeDeviceAuth';
import { useSimpleUserAuth } from '@/utils/dev';

const DURATION_REDIRECT_LOGIN = 3000;

interface FormFields {
    code: string;
    device: string;
}

const authStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();
const {
    deviceSerialNumber,
    verifyDevice,
    isPending,
    errorMessage: chromeDeviceAuthErrorMessage,
} = useChromeDeviceAuth();
const { loginData, isLoggedIn, deviceLoginData } = storeToRefs(authStore);
const formRef = ref();

const errorMessage = computed(() => {
    const { error } = loginData.value;

    if (!error) {
        return undefined;
    }

    return t(getErrorMessageToShow(error));
});

const { handleSubmit, errors, submitCount } = useForm<FormFields>({
    validationSchema,
    initialValues: {
        code: '',
        device: deviceSerialNumber.value,
    },
});

const isSubmitted = computed(() => submitCount.value > 0);
const isLoading = computed(() => (
    loginData.value.status === AsyncStatus.Busy
));

const { value: code, handleChange } = useField<string>('code');
const { value: device, handleChange: handleDeviceChange } = useField<string>('device');
const onSubmit = handleSubmit((values) => {
    authStore.login({
        challenge: values.code,
        identifier: !useSimpleUserAuth()
            ? deviceSerialNumber.value
            : values.device,
    });
});

watch(
    isLoggedIn,
    (isLoggedIn) => {
        if (isLoggedIn) {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            setTimeout(() => {
                const redirectRoute = router.currentRoute?.value?.query?.redirect as string;
                if (redirectRoute) {
                    router.push(redirectRoute);
                } else {
                    router.push({ name: RouteNames.Menu });
                }
            }, DURATION_REDIRECT_LOGIN);
        }
    }
);

watch(deviceSerialNumber, (newDeviceSerialNumber) => {
    if (newDeviceSerialNumber && !useSimpleUserAuth() && !deviceLoginData.value) {
        verifyDevice();
    } else {
        device.value = newDeviceSerialNumber;
    }
});

onMounted(() => {
    // focus first element on mount
    formRef.value?.querySelector('input')?.focus();
});
</script>

<template>
    <div class="login">
        <Loader :show="isPending" />
        <h1 class="login__title">
            <Translate msg="views.login.title" />
        </h1>
        <form
            @submit.prevent="onSubmit"
            class="login__form"
            data-test-id="login-form"
            ref="formRef"
        >
            <FormGroup
                id="login-device"
                :label="t('views.login.form.device.label')"
                :error="isSubmitted && (errors.device) || ''"
                v-slot="{id: id, label: label}"
                v-if="useSimpleUserAuth()"
                class="login__device"
            >
                <BaseInput
                    :id="id"
                    :label="label"
                    :placeholder="t('views.login.form.device.placeholder')"
                    v-model="device"
                    @change="handleDeviceChange"
                    type="text"
                    @keydown.enter="onSubmit"
                />
            </FormGroup>
            <FormGroup
                id="login-code"
                label="views.login.form.code.label"
                :error="isSubmitted && (errors.code || errorMessage) || ''"
                v-slot="{id: id, label: label}"
                hide-label
            >
                <BaseInput
                    :id="id"
                    :label="label"
                    :placeholder="t('views.login.form.code.placeholder')"
                    v-model="code"
                    @change="handleChange"
                    type="text"
                    :loading="isLoading"
                    :disabled="!!chromeDeviceAuthErrorMessage"
                    @keydown.enter="onSubmit"
                />
            </FormGroup>
            <Alert
                v-if="chromeDeviceAuthErrorMessage"
                type="error"
            >
                <Translate :msg="chromeDeviceAuthErrorMessage" />
            </Alert>
        </form>
        <p
            v-if="isLoggedIn"
            class="login__success"
        >
            <SvgIcon
                name="checkmark"
                class="login__success-icon"
            />
            <Translate msg="views.login.form.success" />
        </p>
        <LastLogin class="login__last-login" />
    </div>
</template>

<style lang="scss" scoped>
.login__title {
    margin: 2rem 0 5rem;
    font-size: 3rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
}

.login__error {
    margin-block-end: 2rem;
}

.login__form {
    position: relative;
}

.login__device {
    :deep(.form-group__label) {
        font-size: .8em;
        font-weight: var(--font-weight-medium);
        color: var(--color-danger);
    }
}

.login__last-login {
    margin-block-start: 5rem;
}

.login__success {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: var(--color-success);
}

.login__success-icon {
    --svg-icon-size: 2.4rem;
}
</style>
